:root {
    --dark: #0B0C17;
    --primary: #7221FF;
    --secondary: #47FFE3;
    --light: #F4F3E8;

    font-size: 18px;
}

* {
    margin: 0;
    padding: 0;

}

body {
    background-color: var(--light);
    color: var(--dark);
    display: flex;
    justify-content: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#root {
    padding: 1rem .5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    max-width: 24rem;
    width: 100%;
}

nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

nav h1 {
    flex: 1;
    text-align: center;
}

h1 {
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.4px;
}

button {
    appearance: none;
    border: none;
    background-color: var(--primary);
    color: white;
    padding: .75rem 1.5rem;
    cursor: pointer;
    border-radius: 3rem;
    user-select: none;
}

button:disabled {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;
}

table {
    width: 100%;
    border-collapse: collapse;
}

td {
    width: 50%;
    padding: .75rem;
    border: 1px solid var(--dark);
    font-size: 1rem;
}

td:first-child {
    font-weight: 600;
}